import React from 'react'
import Layout from '../../components/layouts/courseLandingLayout'
import Navbar from '../../components/navbars/courseLandingNavbar';
import { Container, Col, Row } from 'react-bootstrap';
import { ReactTypeformEmbed } from 'react-typeform-embed';
import FocusCourseForm from '../../components/forms/focusCourseForm';
import styled from 'styled-components';

const PrepRegisterContainer = styled(Container)`
  h1 {
    margin-top: 175px;
    text-align: center;
  }

  h3 {
    text-align: center;
    margin-top: 15px;
  }
`
const Apply = ({location}) => {
  return (
    <Layout>
      <Navbar location={location}/>
      <PrepRegisterContainer>    
        <h1>How to Focus When Learning to Code </h1>
        <h3>Course Registration</h3>
        <ReactTypeformEmbed url='https://parsity.typeform.com/to/hURGtV0I' style={{ 'margin-top': '40px', 'height': '500px', 'position': 'static'}} />
      </PrepRegisterContainer>
    </Layout>
  )
}

export default Apply
